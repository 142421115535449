
/**********style logement****************/
main.drl-container{
  margin-left: 5%;
  margin-right: 5%;
}
main .main-content-title{
  font-size: 35px;
  font-weight: 700;
}
main .logement{
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
main .logement .logement-items{
  width: 25%;
  margin-left: 8px;
  margin-right: 8px;
}
.logement .logement-items img{
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.logement .logement-items .logement-item-title{
  font-size: 19px;
  color: #000;
  font-weight: 600;
  margin-top: 5px;
}
/**********style Univers****************/
.univers {
  position: relative;
  max-height: 400px;
  border-radius: 25px;
  overflow: hidden;
  margin: 45px auto;
}
.univer-content-image{
  overflow: hidden;
}
.univers .univer-image{
  position: relative;
  width: 100%;
  border-radius: 20px;
  margin-top: -40px;

}
.univers .univer-description{
  position: absolute;
  left: 8%;
  top: 0;
  width: 400px;
  color: #fff;
  z-index: 9;
}
.univer-description .univer-description-title{
  font-weight: 600;
  font-size: 3.3em;
  margin-top: 30px;
}
.univer-description .univer-description-texte{
  font-size: 19px;
  margin-bottom: 30px;
}
.univer-description .univer-description-bnt .btn{
  background-color: #fff;
  border-radius: 10px;
  color: #222;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}

/************** style decouverte ****************/
.decouverte {
  margin: 60px auto;
}
.decouverte .main-content-title{
  margin-bottom: 0;
}
.decouverte-description {
  color: #222;
  font-size: 27px;
  font-weight: 400;
  margin-bottom: 30px;
}
.decouverte-content-items{
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.decouverte-item{
  width: 33.33%;
  margin-left: 8px;
  margin-right: 8px;
}
.decouverte-item-image{
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.decouverte-item-title{
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-top: 5px;
}
.decouverte-item-texte{
  color: #222;
}

/********************** Footer style *****************/
footer{
  padding-left: 5%;
  padding-right: 5%;
  background-color: #F7F7F7;
  border-top: 1px solid rgb(204, 204, 204);
}
footer a{
  color: #222;
}

footer a:hover{
  text-decoration: underline;
  color: rgb(99, 99, 99);
  transition: 0.2s;
}

.footer-widget {
  padding: 30px 0;
}

.footer-widget-title{
  color: #222;
  font-size: 12px;
  font-weight: 700;
}
.footer1-itms li{
  margin: 10px auto;
}
.footer1-itms li a{
  font-size: 14px;
}

.footer-end {
  padding: 20px 0;
  border-top: 1px solid rgb(204, 204, 204);
}
.footer-end-other ul{
  display: flex;
  justify-content: space-around;
  margin: 0;
  flex-wrap: wrap;
}

.footer-end-social-link  a {
  padding: 0 5px;
}
.footer-end-social-link{
  display: inline-flex;
}
.footer-end-social-link  a span{
  text-decoration: underline;
}
.footer-end-social-link  a:hover{
  text-decoration: none;
}
.footer-end-langue, 
.footer-end-devise{
  display: inline-flex;
}
.footer-end-langue span, 
.footer-end-devise span{
  padding: 0 3px;
}
.media {
  display: flex;
  flex-wrap: wrap;
}
.media a img{
  width: 20px;
  height: 20px;
}

/********* responsive dessign*****************/
@media only screen and (max-width: 1174px){
  .search-banner {
    left: 15%;
    right: 15%;
  }
  .footer-end-copie{
    width: 100%!important;
  }
  .footer-end-social-link{
    width: 35%!important;
    margin-top: 10px;
  }
  .footer-end-other{
    width: 65%!important;
    margin-top: 10px;
  }
  .univer-description-title{
    font-size: 2em!important;
    margin-top: 10px!important;
  }
}
@media only screen and (max-width: 1075px){
  .drl-header-banner .drl-menu{
    padding: 0px 40px 40px 40px;
  }
  .drl-herbgmt-liste {
    padding: 13px 10px;
  }
  .search-banner {
    left: 10%;
    right: 10%;
  }
  .footer-widget.row .col{
    width: 100%!important;
  }
  .footer1-itms{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #777;
    padding-bottom: 10px;
  }
  .footer1-itms li{
      margin: 0;
      width: 33.33%;
      padding: 10px 0;
  }
}
@media only screen and (max-width: 889px){
  .item-all-container{
    justify-content: normal;
  }
  .item-arrivee-depart .DateInput {
    width: 80px;
  }
  .search-banner {
    left: 21%;
    right: 21%;
  }
  .item-all-container{
    flex-wrap: nowrap;
    width: 118%;
  }
  .drl-herbgmt-liste .drl-item-title-voyage {
    width: auto;
  }
  .drl-menu{
    height: 151px;
  }
  .drl-form-menu{
    position: absolute;
      right: 54px;
      top: 103px;
      width: 100%;
  }
  .footer-end{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .footer-end div{
    width: 100%!important;
    text-align: center!important;
    margin-bottom: 10px;
  }
  .univers .univer-image {
    margin-top: 0!important
  }
  main .logement{
    flex-wrap: wrap!important;
    justify-content: space-between;
  }
  main .logement .logement-items {
    width: 45%!important;
  }
}


@media only screen and (max-width: 744px){
  .img-intro{
    overflow-x: hidden;
  }
  .img-intro img{
    width: 1676px!important;
    max-height: 100%!important;
    height: 708px;
    transform: translateX(-923px);
  }
  .drl-navbar-fixed, .search-banner{
    display: none;
  }
  .decouverte-content-items {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .decouverte-item {
    width: 45%!important;
  }
  .footer1-itms li {
    margin: 0;
    width: 50%;
  }
  .univers{
    overflow: visible;
    max-height: 100%!important;
  }
  .univer-description {
    position: relative!important;
    width: 100%!important;
    background-color: #000!important;
    left: 0!important;
    text-align: center;
    padding-bottom: 230px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .univers .univer-image {
    border-radius: 0!important;
    width: 1229px;
    transform: translateX(-537px);
  }
  .univer-description-title{
    font-size: 2.5em!important;
    padding-top: 20px!important;
  }
}
@media only screen and (max-width: 744px){
  .img-intro img{
    height: 600px;
    transform: translateX(-923px);
  }
  main .logement .logement-items {
    width: 100%!important;
  }
  .decouverte-item {
    width: 100%!important;
  }
  .footer1-itms li {
    width: 100%;
  }
}